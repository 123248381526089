<template>
    <div class="teamwork-detail-container">
        <el-dialog
        title="合并文件"
		width="850px"
		@close='closeEvent'
		:append-to-body='true'
        :close-on-click-modal="false" 
        :visible.sync="dialogVisible">
			<DuplicateFileList ref='DuplicateFileList' :viewerKey='viewerKey'  from='merge' @handleSelectionChange='handleSelectionChange'></DuplicateFileList>

            <span slot="footer" class="dialog-footer">
                <el-button type='primary'  @click="onSubmit" :loading='loading' :disabled='multipleSelection.length == 0'>合并保存</el-button>
            </span>
        </el-dialog>
    </div>
</template>
<script>
import DuplicateFileList from './duplicateFileList.vue';
export default {
	components:{
		DuplicateFileList
	},
    data() {
		return {
			dialogVisible: false,
			teamInfo:null,
			viewerKey:'',
			multipleSelection:[],
			loading:false
		};
    },
    methods: {
		// 关闭弹框并清除数据
        closeEvent(){
            this.dialogVisible = false;
			this.teamInfo = null;
			this.multipleSelection = [];
			this.viewerKey = '';
        },
		// 初始化
        show(rows,viewerKey){
			this.dialogVisible = true;
			this.viewerKey = viewerKey;
          	this.teamInfo = rows;
			this.$refs['DuplicateFileList'].show(this.teamInfo,1,true)
        },

		// 多选文件
		handleSelectionChange(val){
			this.multipleSelection = val;
		},
		// 合并保存到我的文档
		onSubmit(){
			this.$prompt('请输入合并后的新名称', '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				inputErrorMessage: '输入不能为空',
				inputValidator: (value) => {       // 点击按钮时，对文本框里面的值进行验证
					if(!value || value.trim() == '') {
						return '输入不能为空';
					}
					if(value.trim().length < 2 || value.trim().length > 20){
						return '名称请输入 2 ~ 20 个字符';
					}
				},
                    
			}).then(({ value }) => {
				this.onSave(value.trim())
			}).catch(() => {
				this.$message({
					type: 'info',
					message: '取消输入'
				});       
			});
		},
		onSave(name){
			this.loading = true;
			let arr = []
			this.multipleSelection.map(item => arr.push(item.id))
			this.$store.dispatch('team/mergeFile',{
				query:{
					replicationIds:arr,
					shareId:this.teamInfo.id,
					replicationDocName:name
				}
			}).then(result => {
				if(result){
					this.loading = false;
					this.closeEvent()
					this.$confirm('保存成功，是否去我的文档中（协同文件夹）查看合并文件？','提示',{
						confirmButtonText: '确定',
						cancelButtonText: '取消',
						type: 'warning'
					}).then(()=>{
						this.$router.push({
							path: '/file/mydoc'
						})
					})
				}
			}).catch(err => {
				this.loading = false;
				this.$message.error(err)
			})
		}
    }
};
</script>
<style lang="less" scoped>
.teamwork-detail-container{
	/deep/.el-dialog__body{
		padding-top: 10px;
	}
	.dialog-footer{
		display: flex;
		justify-content: center;
	}
	.file-version-list{
		
		h1{
			border-left: 4px solid #409EFF;	
			padding-left: 8px;
			margin-bottom: 5px;
		}
	}
	
	.teamwork-detail-info{
		display: flex;
		margin-bottom: 30px;
		h1{
            margin-top: 3px;
        }
		.el-image{
            border: 1px solid #efefef;
            width: 150px;
            height: auto;
            margin-right: 15px;
        }
		.info{
			overflow: hidden;
			margin-bottom: 10px;
			p{
				width: 50%;
				float: left;
				margin: 5px 0;
			}
		}
	}
}
</style>