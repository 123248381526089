<template>
    <div>
        <el-dialog
        :title="title"
        width='700px'
         @close='closeEvent'
        :close-on-click-modal="false" 
        :visible.sync="dialogVisible">
            
            <StepUserInfo  v-if='teamInfo && title =="编辑协同目标"' :targets="targetList" optionType='update' @updateTargets='updateTargets' viewerKey='teamWork' :shareInfo='teamInfo' ref="stepUserInfo"></StepUserInfo>

             <el-table
                v-if='teamInfo && title =="查看协同目标"'
                :data="orderList"
                class="target_table"
                :border='true'
                size="small"
                row-key="value"
                style="width: 100%; margin-top: 20px">
                    <el-table-column
                        type="index"
                        label="序号"
                        align="center"
                        width="50">
                    </el-table-column>
                    <el-table-column
                        label="姓名/手机号">
                        <template slot-scope="scope">
                        <span>{{ scope.row.type == 'TARGET_PHONE' ? scope.row.value : scope.row.label }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column
                        prop="targetType"
                        label="类型">
                        <template slot-scope="scope">
                        <span v-if="scope.row.type == 'TARGET_PHONE'">手机授权</span>
                        <span v-else-if="scope.row.type == 'TARGET_ORG'">组织授权</span>
                        <span v-else-if="scope.row.type == 'TARGET_GROUP'">部门授权</span>
                        <span v-else>用户授权</span>
                        </template>
                    </el-table-column>
                </el-table>

            <span slot="footer" class="dialog-footer">
                <el-button size="small" @click="closeEvent">关 闭</el-button>
            </span>
        </el-dialog>
    </div>
</template>
<script>
 import StepUserInfo from '@/packages/FileCart/src/StepUserInfo.vue'
  export default {
    components:{
        StepUserInfo
    },
    data() {
      return {
        dialogVisible: false,
        teamInfo:null,//当前协同信息
        title:'',
        orderList:[]//顺序协同
      };
    },
    computed:{
        //目标列表
        targetList() {
            const targets = this.$store.state.teamWork.targets
            return Object.keys(targets).map(k => targets[k])
        },
    },
    methods: {
        closeEvent(){
            this.dialogVisible = false;
            this.teamInfo = null;
            this.orderList = []
            this.$store.commit('teamWork/resetTargets');
        },
        // 页面初始化
        show(row){
            this.teamInfo = row;
            // 如果协同结束，则不可编辑协同目标，且只有协同方式可编辑
            if(row.state == 0){
                if(row.teamWorkType == 0){
                     this.title = '编辑协同目标'
                }else{
                    this.title = '查看协同目标'
                }
            }else{
                this.title = '查看协同目标'
            }
            if(this.teamInfo.teamWorkType == 0 && row.state == 0){
                row.targetList.map(item => {
                    this.$store.commit("teamWork/addTarget", {
                        label:item.attrValue,
                        value:item.targetValue,
                        type:item.targetType
                    })
                })
            }else{
                row.targetList.map(item => {
                    this.orderList.push({
                        label:item.attrValue,
                        value:item.targetValue,
                        type:item.targetType
                    })
                })
            }

            this.dialogVisible = true;
        },  
        //追加目标
		onTargetAdd(target){
			this.$store.dispatch('teamWork/addTargets', {
				id: this.teamInfo.id,
				target
			}).then(state => {
                this.$message.success("授权已完成")
                this.$emit('loadByQuery')
            })
			.catch(err => {
				console.log(err)
				this.$message.error("授权失败!")
			})
		},

		//移除目标
		onTargetRemoved(target){
			this.$store.dispatch('teamWork/deleteTargets', {
				id: this.teamInfo.id,
				target
			}).then(state => {
                this.$message.success("授权已完成")
                this.$emit('loadByQuery')
            })
			.catch(err => {
				console.log(err)
				this.$message.error("收回失败!")
			})
		},
        updateTargets(user,type){
            let target = {
                attrs:{
                    name:user.label
                },
                faceUrl:user.faceUrl,
                label:user.label,
                type:user.type,
                value:user.value
            }
            if(type == 'add'){
                this.onTargetAdd(target)
            }else{
                this.onTargetRemoved(target)
            }
        }
    }
  };
</script>
<style lang="less" scoped>
.send-type-style{
    margin-bottom: 15px;
}
.target_table{
    height: 300px;
    overflow: scroll;
}
</style>