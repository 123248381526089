<template>
    <div class="teamWork-List-container">
        <div class="search-container">
            <el-button type='success' v-if='viewerKey == "myCreate"' class="create-btn" @click="createTeamWork"  size='small'>发起协同</el-button>
            <div v-else></div>
            <el-form @submit.native.prevent :inline="true" :model="query" class="demo-form-inline">
                <el-form-item label="协同名称">
                    <el-input @keyup.enter.native="onSearch" v-model="query.name" clearable placeholder="请输入搜索关键字" size="small" @clear="clearSearch"></el-input>
                </el-form-item>
                <el-form-item label="协同状态" v-if='viewerKey != "myPending" '>
                    <el-select size="small" v-model="query.state" placeholder="请选择协同状态">
                        <el-option label="全部" :value="null"></el-option>
                        <el-option label="进行中" :value="0"></el-option>
                        <el-option label="已结束" :value="3"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" size="small" icon="el-icon-search" @click="onSearch">搜索</el-button>
                </el-form-item>
            </el-form>  
        </div>
       
        <el-table
            v-if='tableData.length != 0'
            class="teamWork-List"
            :data="tableData"
            @row-click="handleRowClickEvent"
            style="width: 100%">
            <el-table-column
                type="index"
                label="序号"
                align="center"
                width="50">
            </el-table-column>
            <el-table-column
                label="协同名称"
                width="300"
                show-overflow-tooltip
                prop="title">
                <template slot-scope="scope" >
                    <img :src="thumbIcon(scope.row.resourceList[0])" class="thumbIcon" alt="">
                    {{scope.row.name}}
                </template>
            </el-table-column>
             <el-table-column
                v-if='viewerKey != "myCreate"'
                align="center"
                label="协同发起者">
                <template slot-scope="scope">
                   {{scope.row.ownerName}}
                </template>
            </el-table-column>
             <el-table-column
                align="center"
                label="协同方式">
                <template slot-scope="scope">
                    {{scope.row.teamWorkType == 0 ? '协同处理':'顺序协同'}}
                </template>
            </el-table-column>
            <el-table-column
                label="授权目标"
                prop="user">
                <template slot-scope="scope">
                    <div v-if="scope.row.targetList.length >= 5">
                        <el-avatar size="small" :src="userIcon()"></el-avatar>
                        <el-avatar size="small" :src="userIcon()"></el-avatar>
                        <el-avatar size="small" :src="userIcon()"></el-avatar>
                        <el-avatar size="small" :src="userIcon()"></el-avatar>
                        <el-avatar size="small"> +{{scope.row.targetList.length-4}}</el-avatar>
                    </div>
                    <div v-else>
                        <el-avatar v-for="(item,index) in scope.row.targetList" :key="index" size="small" :src="userIcon()"></el-avatar>
                    </div>
                </template>
            </el-table-column>
            <el-table-column  v-if='viewerKey == "myCreate" || viewerKey == "myJoin" '
            align="center" 
            label="状态">
                <template slot-scope="scope">
                    <span v-if='scope.row.state == 0' style="color:#E6A23C">进行中</span>
                    <span v-if='scope.row.state == 3' style="color:#909399">已结束</span>
                </template>
            </el-table-column>
            <el-table-column
                align="center"
                label="发起时间">
                <template slot-scope="scope">
                    <span class="date">{{scope.row.createDate}}</span>
                </template>
            </el-table-column>
            <el-table-column
					align="center"
					label="操作">
					<template slot-scope="scope">
						<el-button v-if='viewerKey == "myCreate" && scope.row.state == 0' type='text' @click.stop="handleRowClick(scope.row,'merge')">合并</el-button>
						<el-button type='text'  @click.stop="handleRowClick(scope.row,'detail')">详情</el-button>
						<!-- <el-button v-if='viewerKey != "myCreate" && scope.row.state == 0' type='text'  @click.stop="handleRowClick(scope.row,'edit')">编辑</el-button> -->
						<!-- <el-button v-if='viewerKey == "myCreate"' type='text' @click="handleRowClick(scope.row,'version')">合并记录</el-button> -->
                        <el-button v-if='viewerKey == "myCreate" && scope.row.state == 0' type='text' @click.stop="handleRowClick(scope.row,'over')">结束协同</el-button>
                        <el-button v-if='viewerKey == "myCreate"' type='text' @click.stop="handleRowClick(scope.row,'target')">参与者</el-button>
                    </template>
				</el-table-column>
        </el-table>
        <div class="no-data-box" v-if='tableData.length == 0'>
            <img src="@/assets/no-file.svg" alt="">
            <span>暂无数据</span>
        </div>
        <div class="pagination"  v-if='tableData.length != 0'>
            <el-pagination
            class="pagebox"
            @current-change="pageChanged"
            :page-size="query.pageSize"
            layout="total,  prev, pager, next, jumper"
            :total="total">
            </el-pagination>
        </div>

        <!-- 协同详情 -->
        <Detail ref='detail' :viewerKey='viewerKey' @loadByQuery='loadByQuery'></Detail>

        <!-- 合并文件 -->
        <MergeFile ref='MergeFile'></MergeFile>

        <!-- 合并记录 -->
        <VersionList ref='VersionList'></VersionList>

         <!-- 发起协同 -->
	    <CreateTeamWork ref='teamWork' @clearSearch='clearSearch'></CreateTeamWork>

        <!-- 更改授权目标 -->
        <UpdateTargets ref='UpdateTargets' @loadByQuery='loadByQuery'></UpdateTargets>

    </div>
</template>
<script>
import Detail from './Detail.vue'
import MergeFile from './MergeFile.vue'
import CreateTeamWork from './CreateTeamWork.vue'
import iconFolder from '@/assets/img/folder.png'
import iconDefault from '@/assets/img/icon-def.png'
import iconUnknown from '@/assets/img/icon-unknown.png'
import iconImage from '@/assets/img/icon-picture.png'
import iconOFD from '@/assets/img/icon-ofd.png'
import iconAudio from '@/assets/img/icon-audio.png'
import iconWord from '@/assets/img/icon-word.png'
import iconPpt from '@/assets/img/icon-ppt.png'
import iconPdf from '@/assets/img/icon-pdf.png'
import iconExcl from '@/assets/img/icon-excl.png'
import iconMP3 from '@/assets/doc-icon/mp3.png'
import iconMP4 from '@/assets/doc-icon/mp4.png'
import iconZIP from '@/assets/doc-icon/zip.png'
import userIcon from '@/assets/userIcon.png'
import VersionList from './VersionList.vue'
import UpdateTargets from './updateTargets.vue'

//后缀映射
const suffixIconCache = {
  def: iconDefault,
  folder: iconFolder,
  unknown: iconUnknown,
  png: iconImage,
  jpg: iconImage,
  jpeg: iconImage,
  bmp: iconImage,
  ofd: iconOFD,
  doc: iconWord,
  DOC: iconWord,
  docx: iconWord,
   mp3: iconMP3,
  mp4: iconMP4,
  zip: iconZIP,
  ppt:iconPpt,
  pptx:iconPpt,
  pdf:iconPdf,
  xls:iconExcl,
  xlsx:iconExcl
}
export default {
    components:{
        Detail,
        MergeFile,
        VersionList,
        CreateTeamWork,
        UpdateTargets
    },
    props:{
        viewerKey:{
            type:String,
            default:'myCreate'
        }
    },
	data() {
		return {
			query:{
                name:'',
                pageNum:1,
                pageSize:10,
                state:null,//0 进行中 3已结束
            }
		};
	},
	computed: { 
        tableData(){
            return this.$store.state.team.views[this.viewerKey].list || []
        },
        total(){
            return this.$store.state.team.views[this.viewerKey].paging.total;
        }
    },
	methods: {
         // 创建协同
		createTeamWork(){
			this.$refs.teamWork.init();
		},
        // 清除搜索条件
        clearSearch(){
            this.query.pageNum = 1;
            this.query.name = '';
            this.loadByQuery()
        },
        /**
         * 图标
         */
        thumbIcon(doc){
            let iconKey = "unknown"
            if(doc.documentType==1 &&doc.orgId!=null){
                iconKey='org'
            }else if(doc.documentType == 2 &&doc.orgId!=null){
                iconKey='depart'
            }else{
                if(doc.type == 0){
                    iconKey = "folder"
                }else if(suffixIconCache.hasOwnProperty(doc.suffix)){
                    iconKey = doc.suffix
                }
            }

            return suffixIconCache[iconKey] 
        },
        // 检索
        onSearch(){
            this.query.pageNum = 1;
            this.loadByQuery()
        },
        // 用户图标
        userIcon(){
            return userIcon
        },
        // 分页
        pageChanged(num){
            this.query.pageNum = num;
            this.loadByQuery()
        },
        // 加载列表
        loadByQuery(){
            let interfaceName = 'getMyPendingList'
            interfaceName = this.viewerKey == 'myPending'? 'getMyPendingList':(this.viewerKey == 'myCreate' ? "getMyCreateList":"getMyJoinList") 
            this.$store.dispatch('team/loadByQuery',{
                viewerKey:this.viewerKey,
                reset:true,
                query:this.query,
                interfaceName:interfaceName
            })
        }, 
        //行点击
        handleRowClickEvent(row, column, event) {
            this.handleRowClick(row,'detail')
        },
        // 统一事件处理
        handleRowClick(row,type){
            switch(type){
                case 'merge':
                    this.$refs['MergeFile'].dialogVisible = true;
                    this.$nextTick(()=>{
                        this.$refs['MergeFile'].show(row,this.viewerKey)
                    })
                  
                    break;
                case 'version':
                    this.$refs['VersionList'].show(row)
                    break;
                case 'detail':
                    this.$refs['detail'].show(row,this.viewerKey)
                    break;
                case 'over':
                    this.$confirm('是否确定要结束此协同任务？','提示',{
						confirmButtonText: '确定',
						cancelButtonText: '取消',
						type: 'warning'
					}).then(()=>{
                        this.$store.dispatch('team/overTeamWork',{
                            id:row.id
                        }).then(code  =>{
                            if(code){
                                this.$message.success('操作成功!')
                            }else{
                                this.$message.error('操作失败!')
                            }
                        }).catch(msg => {
                             this.$message.error(msg)
                        })
					})
                    break;
                case 'edit':
                    this.$ebus.$emit('publicReader',{
						shareId:row.id, 
						id:row.resourceList[0].documentId,
						documentId:row.resourceList[0].documentId,
                        model:1
					})
                    break;
                case 'target':
                    this.$refs['UpdateTargets'].show(row);
                    break;
            }
        }
    },
	mounted() {
        this.loadByQuery(1);
    },
};
</script>
<style lang='less' scoped>

.teamWork-List-container{
    .search-container{
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    .pagination{
        margin-top: 10px;
    }

    /deep/.teamWork-List{
        // thead{
        //     display: none;
        // }
        thead {
            color: #909399;
            font-weight: 500;
        }
        .has-gutter tr th {
            border: none;
            background: #F2F2F2;
            color: #666;
            padding: 8px 0;
        }
        .cell {
            box-sizing: border-box;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: normal;
            word-break: break-all;
            line-height: 23px;
            padding-right: 10px;
            
        }
        font-size: 13px;
    }
    /deep/.el-dialog__body{
        padding-top: 10px;
    }
    
    .teamWork-List /deep/.cell{
        // display: flex;
        // align-items: center;
        img{
            vertical-align: middle;
        }
    }
    .el-avatar--small{
        margin-right: 5px;
    }
    .teamWork-List  .thumbIcon{
        width: 20px;
        vertical-align: baseline;
        margin-right: 6px;
    }
    .date{
        color: #999;
    }
}
</style>