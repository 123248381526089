<template>
    <div>
        <el-dialog
        title="协同合并记录"
        width='700px'
         @close='closeEvent'
        :close-on-click-modal="false" 
        :visible.sync="dialogVisible">
             <el-table
                :data="tableData"
                style="width: 100%">
                <el-table-column
                    prop="title"
                    label="版本名称">
                </el-table-column>
                <el-table-column
                    prop="time"
                    label="合并时间"
                    width="180">
                </el-table-column>
                <el-table-column
					width="130"
					align="center"
					label="操作">
					<template slot-scope="scope">
						<el-button size='mini'  type="success" @click="handleClick(scope.row,'read')">阅读</el-button>
					</template>
				</el-table-column>
            </el-table>
            <span slot="footer" class="dialog-footer">
                <el-button size="small"  @click="closeEvent">关 闭</el-button>
            </span>
        </el-dialog>
    </div>
</template>
<script>
  export default {
    components:{
    },
    data() {
      return {
        dialogVisible: false,
        tableData: [{
            title: '可信电子文件管理平台_使用手册',
            time: '2023-03-02 12:09:00'
        },{
            title: '可信电子文件管理平台_使用手册(1)',
            time: '2023-03-02 12:09:00'
        },{
            title: '可信电子文件管理平台_使用手册(2)',
            time: '2023-03-02 12:09:00'
        }],
        rowsInfo:{}//当前协同信息
      };
    },
    methods: {
        show(rows){
            this.dialogVisible = true;
            this.rowsInfo = rows;
        },
        //统一事件处理
		handleClick(row,type){
			switch(type){
				case 'read':
					this.$ebus.$emit('publicReader',{
						shareId:'1630810903508013057', 
						id:'1625316208469360641',
						documentId:'1625316208469360641'
					})
				break;
			}
		},
        closeEvent(){
            this.rowsInfo = {};
            this.dialogVisible = false;
        }
    }
  };
</script>
<style lang="less" scoped>
.send-type-style{
    margin-bottom: 15px;
}
</style>